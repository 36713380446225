import { CardProduct, CardProductSkeleton } from '@mf/common/components/index';
import { InfiniteData } from '@tanstack/query-core';
import { useEffect, useLayoutEffect, useState } from 'react';
import { ButtonSecondarySize } from '@printi/ds-offset-react-core';
import { useRouter } from 'next/navigation';
import Link from 'next/link';
import { useLayout } from '@mf/common/components/Media/Layout';
import strapiProduct from '@/repositories/aecomStrapiCms/product';
import { TProductsListing } from '@/repositories/aecomStrapiCms/product/types';
// eslint-disable-next-line
import { TProductSuggestions } from '../SearchAIDrawer';
// eslint-disable-next-line
import * as S from './SearchAIProductsSuggest.styles';

interface SearchAIProductsSuggestProps {
  heading: string;
  options: TProductSuggestions[];
}

export const SearchAIProductsSuggest = ({
  heading,
  options,
}: SearchAIProductsSuggestProps) => {
  const [pageSize, setPageSize] = useState(20);
  const { push, prefetch } = useRouter();
  const { setShowSearchPage } = useLayout();

  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  const { useProductsListing } = strapiProduct(auth);

  const { data: products } = useProductsListing({
    params: {
      pagination: { page: 0, pageSize },
      filters: {
        slug: {
          $in: options.map((option) => option.slug),
        },
      },
      populate: ['banner_image', 'small_image', 'small_image_hover'],
    },
  });

  useLayoutEffect(() => {
    prefetch('/produtos');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const element = document.getElementById('SearchAI');
    const observer = new IntersectionObserver((element) => {
      if (element[0].isIntersecting) {
        const itemsMap = products?.pages[0]?.data.map((item) => ({
          item_id: item.attributes.final_product_id,
          item_name: item.attributes.name,
          affiliation: 'Printi',
          discount: 0,
          index: 0,
          item_brand: 'Printi',
          item_list_id: heading?.toLowerCase().replace(' ', '_') ?? '',
          item_list_name: heading,
          price: item?.attributes?.minimal_price?.toString() ?? '0',
          quantity: item.attributes.minimal_quantity,
        }));

        if (products) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'view_item_list',
            event_name: 'view_item_list',
            item_list_id: 'search-ai',
            item_list_name: 'SearchAI',
            ecommerce: {
              currency: 'BRL',
              items: itemsMap,
            },
          });
        }
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [products, heading]);

  const handleButtonSecondaryClick = async () => {
    if (pageSize < options.length) {
      setPageSize(pageSize + 3);
    } else {
      push('/produtos');
      await setTimeout(() => setShowSearchPage(false), 1200);
    }
  };

  return (
    <S.Container>
      <S.ProductsWrapper id="SearchAI">
        {!products &&
          Array.from({ length: 4 }).map((_, index) => (
            <S.CardProductWrapper key={index}>
              <CardProductSkeleton />
            </S.CardProductWrapper>
          ))}
        {(products as InfiniteData<TProductsListing>)?.pages[0]?.data.map(
          (product, index) => (
            <Link
              key={index}
              href={`/${product.attributes.slug}/`}
              prefetch={true}
              onClick={() => setShowSearchPage(false)}
            >
              <CardProduct product={product} />
            </Link>
          ),
        )}
      </S.ProductsWrapper>
      <S.ButtonSecondary
        size={ButtonSecondarySize.LG}
        onClick={handleButtonSecondaryClick}
      >
        {pageSize < options.length ? 'Ver mais' : 'Acessar todos'}
      </S.ButtonSecondary>
    </S.Container>
  );
};
