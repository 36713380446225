import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';
import { Container as SearchAutocompleteContainer } from './SearchAutocomplete/SearchAutocomplete.styles';

export const Overlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9000;
  opacity: 1;
  transition: opacity 0.1s;

  ${({ $isOpen }) =>
    !$isOpen &&
    `
        opacity: 0;
        pointer-events: none;
    `}
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100dvh;
  padding: var(--spacing-5x) 0 0 0;
  box-sizing: border-box;
  overflow-y: scroll;
  border-bottom: var(--border-width-md);
  max-width: 1440px;
  margin: 0 auto;
  overflow-y: hidden;

  @media screen and (max-height: 800px) {
    overflow-y: auto;
  }

  @media (max-width: 1025px) {
    padding: var(--spacing-5x) var(--spacing-2x) 0 var(--spacing-2x);
  }

  @media (max-width: 768px) {
    padding: var(--spacing-2x) var(--size-2_5x) 0 var(--size-2_5x);
  }
`;

export const Content = styled.div`
  padding: var(--spacing-6x) var(--spacing-5x);
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  margin-top: var(--spacing-3x);

  @media screen and (max-width: 767px) {
    padding: 0;
    max-width: none;
    max-height: none;
    overflow-y: unset;
    #CarouselProducts {
      > div > div {
        width: 152px;
        min-width: unset !important;
      }
    }
  }
`;

export const ContentFull = styled.div`
  padding: var(--spacing-6x) var(--spacing-5x);
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;

  ${media.lessThan('mobile')} {
    padding: 0;
    max-width: none;
    max-height: none;

    ${Modal} {
      gap: 0;
    }
  }
`;

export const CloseButtonWrapper = styled.div`
  position: fixed;
  bottom: var(--spacing-5x);
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: var(--elements-bg-color-01);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9000;
  opacity: 1;
  transition-property: opacity, transform, translateY;
  transition-duration: var(--duration-moderate-02);
  transition-timing-function: var(--curve-productive-exit);
  transition-delay: 0s;

  ${Modal} {
    ${SearchAutocompleteContainer}, ${Content}, ${CloseButtonWrapper} {
      opacity: 1;
      transform: translateY(0px);
      transition:
        opacity 0.5s,
        transform 0.5s;
    }
    ${SearchAutocompleteContainer} {
      transition-delay: 0.3s;
    }
    ${Content} {
      transition-delay: 0.5s;
    }
    ${CloseButtonWrapper} {
      transition-delay: 0.7s;
    }
  }

  ${({ $isOpen }) =>
    !$isOpen &&
    `
        opacity: 0;
        pointer-events: none;
        transform: translateY(40vw);
        transition-timing-function: var(--curve-productive-entrance);
        transition-delay: 1s;
        ${Modal} {
          ${SearchAutocompleteContainer}, ${Content}, ${CloseButtonWrapper} {
            opacity: 0;
            transform: translateY(100px);
          }
          ${SearchAutocompleteContainer} {
            transition-delay: 0.7s;
          }
          ${Content} {
            transition-delay: 0.5s;
          }
          ${CloseButtonWrapper} {
            transition-delay: 0.3s;
          }
        }
    `}
`;
