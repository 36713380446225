'use client';

import { useLayoutEffect, useState } from 'react';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { FsParagraph } from '@printi/ds-offset-react-core';
import type { IChatMessageMarkdownProps } from './ChatMessageMarkdown.types';
import { StyledMarkdown } from './ChatMessageMarkdown.styles';

export function ChatMessageMarkdown({
  ...props
}: Readonly<IChatMessageMarkdownProps>) {
  const [isClientSide, setIsClientSide] = useState(false);
  const { children, ...rest } = props;

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && !isClientSide) {
      setIsClientSide(true);
    }
  }, [isClientSide]);

  return (
    isClientSide && (
      <StyledMarkdown
        rehypePlugins={[rehypeKatex, rehypeRaw]}
        components={{
          pre: ({ children }) => <FsParagraph>{children}</FsParagraph>,
          code: ({ children }) => <>{children}</>,
          a: ({ children, href }) => (
            <a href={href} target="_blank" rel="noopener">
              {children}
            </a>
          ),
        }}
        {...rest}
      >
        {children as string}
      </StyledMarkdown>
    )
  );
}
