import React from 'react';
import { FsIcon, IconSize } from '@printi/ds-offset-react-core';
import * as S from './SearchAutocomplete.styles';

interface SearchAutocompleteProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  customIcon?: React.ReactNode;
  showIcon?: boolean;
}

const SearchIcon = (customIcon: string | React.ReactNode) => {
  if (customIcon) {
    return customIcon;
  } else {
    return <FsIcon icon="SearchLine" size={IconSize.LG} />;
  }
};

export const SearchAutocomplete = React.forwardRef<
  HTMLInputElement,
  SearchAutocompleteProps
>(({ customIcon, showIcon = true, ...props }, ref) => {
  return (
    <S.Container>
      <S.Input type="text" ref={ref} {...props} />
      {showIcon && SearchIcon(customIcon)}
    </S.Container>
  );
});
SearchAutocomplete.displayName = 'SearchAutocomplete';

export default SearchAutocomplete;
