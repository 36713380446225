'use client';

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Carousel } from '@mf/common/components/index';
import { FsButtonMini } from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
// eslint-disable-next-line import/order
import { useDebounce } from '@mf/common/utils/hooks/useDebounce';
import { useLogManager } from '@mf/common/components/Logs/LogManager.hooks';
import { useSession } from 'next-auth/react';
import strapiProduct from '@/repositories/aecomStrapiCms/product';
import { SearchAIDrawer } from '../SearchAIDrawer';
import { useCartStore } from '../../../../../../packages/common/src/store/cart';
import { CustomSession } from '../../../../../../packages/common/src/config/nextAuth';
import { LogEventType } from '../../../../../../packages/common/src/components/Logs/providers/logProvider';
import { SearchResultType, SearchResults } from './SearchResults/SearchResults';
import { SearchAutocomplete } from './SearchAutocomplete/SearchAutocomplete';
// eslint-disable-next-line import/order
import * as S from './SearchModal.styles';

const toggleBodyScroll = (shouldBlock: boolean) => {
  if (shouldBlock) {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  } else {
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.width = '';
  }
};

const sanitizeInput = (input: string) => input.replace(/[&"'<>]/g, '');

export const SearchModal = () => {
  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };
  const API_KEY: string = process.env.TYPESENSE_API_KEY as string;

  const { useBestSellingProducts } = strapiProduct(auth);
  const { data: bestSelling } = useBestSellingProducts({
    pageSize: 8,
  });
  const { customerId } = useCartStore();
  const { trackEvent } = useLogManager();
  const { data: session } = useSession();
  const userSession = session as CustomSession;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [results, setResults] = useState<SearchResultType>({
    hits: [],
    found: 0,
    page: 0,
  });
  const { showSearchPage, setShowSearchPage } = useLayout();
  const [isClient, setIsClient] = useState<boolean>(false);
  const [isClientSide, setIsClientSide] = useState<boolean>(false);

  // Hooks
  const debouncedSearchTerm = useDebounce(searchTerm, 400);

  const escToClosePage = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        setShowSearchPage(false);
        setIsOpenDrawer(false);
      }
    },
    [setShowSearchPage],
  );

  const escToCloseDrawer = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        setIsOpenDrawer(false);
      }
    },
    [setIsOpenDrawer],
  );

  const sendEvent = (
    event: LogEventType,
    params: {
      result?: string[];
      final_product_id?: string;
      slug?: string;
    },
  ) => {
    trackEvent(event, {
      details: {
        ...params,
        searchTerm,
        customer_id: customerId,
        session_id: userSession,
      },
    });
  };

  useEffect(() => {
    toggleBodyScroll(showSearchPage);

    return () => toggleBodyScroll(false);
  }, [showSearchPage]);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && !isClientSide) {
      setIsClientSide(true);
    }
  }, [isClientSide]);

  useEffect(() => {
    if (isOpenDrawer === false || showSearchPage === false) {
      setSearchTerm('');
    }
  }, [isOpenDrawer, showSearchPage]);

  useEffect(() => {
    if (showSearchPage) {
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 300);
      searchInputRef.current?.focus();
      document.addEventListener('keydown', escToClosePage);
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', escToClosePage);
    };
  }, [showSearchPage, escToClosePage]);

  useEffect(() => {
    if (isOpenDrawer) {
      document.removeEventListener('keydown', escToClosePage);
      document.addEventListener('keydown', escToCloseDrawer);
    } else {
      document.removeEventListener('keydown', escToCloseDrawer);
      document.addEventListener('keydown', escToClosePage);
      searchInputRef.current?.focus();
    }
    return () => {
      document.removeEventListener('keydown', escToCloseDrawer);
    };
  }, [isOpenDrawer, escToCloseDrawer, escToClosePage]);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      return;
    }

    (async () => {
      const url = new URL(
        `${process.env.TYPESENSE_URL}/collections/${process.env.TYPESENSE_PRODUCTS_INDEX_NAME}/documents/search`,
      );

      url.searchParams.set('q', debouncedSearchTerm);
      url.searchParams.set(
        'preset',
        String(process.env.TYPESENSE_PRODUCTS_SEARCH_PRESET_NAME),
      );

      const response = await fetch(url, {
        headers: { 'X-TypeSense-Api-Key': API_KEY },
      });

      const data = await response.json();
      sendEvent('search', {
        result: data?.hits?.map(
          (hit: { document: { final_product_id: string } }) =>
            hit.document.final_product_id,
        ),
      });
      setResults(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setIsClient(true);
  }, [setIsClient]);

  const handleChange = (target: HTMLInputElement) => {
    setSearchTerm(sanitizeInput(target.value));
  };

  const handleBlur = () => {
    if (searchTerm !== '') {
      window.dataLayer.push({
        event: 'genericEvent',
        event_name: 'search',
        event_parameter_1: searchTerm,
      });
      searchInputRef.current?.blur();
    }
  };

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchInputRef.current?.blur();
    }
  };

  const customCloseButton = () => {
    return (
      <FsButtonMini
        onClick={() => {
          setShowSearchPage(false);
          setIsOpenDrawer(false);
        }}
      >
        Fechar
      </FsButtonMini>
    );
  };

  const onProductClickHandle = (slug: string, final_product_id: string) => {
    sendEvent('search_select_item', { slug, final_product_id });
  };

  return (
    isClientSide && (
      <>
        <S.Overlay $isOpen={showSearchPage}></S.Overlay>
        <S.Container $isOpen={showSearchPage}>
          <S.Modal>
            <SearchAutocomplete
              ref={searchInputRef}
              placeholder="Busque por produtos aqui..."
              onChange={({ target }) =>
                handleChange(target as HTMLInputElement)
              }
              value={searchTerm}
              showIcon={true}
              customIcon={customCloseButton()}
              onBlur={handleBlur}
              onKeyDown={handleEnterKey}
            />
            {searchTerm.length > 2 && results.page > 0 ? (
              <S.ContentFull
                onScroll={() => {
                  searchInputRef.current?.blur();
                  window.scrollTo(0, window.scrollY + 1);
                }}
              >
                <SearchResults
                  results={results}
                  setIsOpenDrawer={setIsOpenDrawer}
                  onProductClick={onProductClickHandle}
                />
              </S.ContentFull>
            ) : (
              isClient && (
                <S.Content>
                  <Carousel
                    heading="Mais vendidos"
                    cards={bestSelling?.data || []}
                  />
                </S.Content>
              )
            )}
          </S.Modal>
          <SearchAIDrawer
            isOpen={isOpenDrawer}
            term={searchTerm}
            setOpen={(e) => {
              setSearchTerm('');
              setIsOpenDrawer(e);
            }}
          />
        </S.Container>
      </>
    )
  );
};
