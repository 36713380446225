import {
  DrawerPositions,
  DrawerSizes,
  FsChatInput,
  FsDrawer,
} from '@printi/ds-offset-react-core';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import * as S from './SearchAIDrawer.styles';
import { SearchAIProductsSuggest } from './SearchAIProductsSuggest';
import { ChatMessageMarkdown } from './ChatMessageMarkdown/ChatMessageMarkdown';

interface SearchAIDrawerProps {
  isOpen: boolean;
  term: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export type TProductSuggestions = {
  slug: string;
  name: string;
  long_description: string;
  minimal_price: number;
  minimal_quantity: number;
  segmentos_de_negocio: string;
  sinonimos: string;
  tag_1: string;
  tag_2: string;
  tag_3: string;
  tag_4: string;
  tag_5: string;
};

type TMessages = {
  role: 'user' | 'assistant' | 'assistant-waiting';
  content?: string;
  options?: {
    direct_match: TProductSuggestions[];
    creative_matches: TProductSuggestions[];
    surprise_items: TProductSuggestions[];
  };
};

export const SearchAIDrawer = ({
  isOpen,
  term,
  setOpen,
}: SearchAIDrawerProps) => {
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const [history, setHistory] = useState<TMessages[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [prompt, setPrompt] = useState<string>('');
  const chatInputRef = useRef<HTMLInputElement>(null);
  const [thinking, setThinking] = useState(false);
  const heading = 'Printi AI - Auxiliar de busca';

  useEffect(() => {
    chatHistoryRef.current?.scrollTo(0, chatHistoryRef.current.scrollHeight);
  }, [history]);

  const handleChange = (userMessage?: string) => {
    setThinking(true);

    const filterMessages = history.filter(
      (message) => message.role !== 'assistant-waiting',
    );
    if (history.length === 0) {
      setHistory([
        {
          role: 'user',
          content: term,
        },
      ]);
      filterMessages.push({
        role: 'user',
        content: term,
      });
    }
    const messageBody = [...filterMessages];

    if (userMessage) {
      messageBody.push({ role: 'user', content: userMessage });
      setHistory(messageBody);
      setPrompt('');
    }

    fetch('/api/search', {
      method: 'POST',
      body: JSON.stringify({ messages: messageBody }),
    })
      .then((response) => response.json())
      .then((data) => {
        setThinking(false);
        setHistory([...messageBody, data]);
      })
      .catch(() => {
        setTimeout(() => {
          setHistory([
            ...messageBody,
            {
              role: 'assistant',
              content: 'Desculpe, tive um problema. Vou tentar novamente.',
            },
          ]);
          setThinking(false);
          handleChange();
        }, 1500);
      });
  };

  useEffect(() => {
    if (!isOpen) {
      setHistory([]);
      setShowInput(false);
      return;
    }

    handleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (history.length < 2) {
      return;
    }
    setShowInput(true);
  }, [history]);

  useEffect(() => {
    const txtarea = chatInputRef.current?.querySelector('textarea');

    if (txtarea) {
      showInput ? txtarea.focus() : txtarea.blur();
    }
  }, [showInput]);

  const handleSendMessage = () => {
    handleChange(prompt);
  };

  const getUserType = (role: string) => {
    if (role === 'user') {
      return 'user';
    }

    if (role === 'assistant') {
      return 'AI answer';
    }

    return 'AI loading';
  };

  return (
    <FsDrawer
      position={DrawerPositions.Right}
      isOpen={isOpen}
      showNavBar={true}
      size={DrawerSizes.LG}
      onClose={() => setOpen(false)}
    >
      <S.ChatContainer>
        <S.ChatHistory ref={chatHistoryRef}>
          {history.map(
            (message, index) =>
              index > 0 && (
                <S.Chat
                  key={index}
                  type={getUserType(message.role)}
                  userName={message.role === 'user' ? 'Você' : 'Printi AI'}
                >
                  <S.MessageChat>
                    <ChatMessageMarkdown>{message.content}</ChatMessageMarkdown>
                    {message.options && (
                      <SearchAIProductsSuggest
                        heading={heading}
                        options={[
                          ...message.options.direct_match,
                          ...message.options.creative_matches,
                          ...message.options.surprise_items,
                        ]}
                      />
                    )}
                  </S.MessageChat>
                </S.Chat>
              ),
          )}
          {thinking && (
            <S.Chat type="AI loading" userName="Printi AI">
              <></>
            </S.Chat>
          )}
        </S.ChatHistory>
        {showInput && (
          <S.ChatInputContainer>
            <FsChatInput
              id="chat-input"
              ref={chatInputRef}
              placeholder="Escreva aqui"
              value={prompt}
              onChange={(event) => setPrompt(event.target.value)}
              onKeyEnter={handleSendMessage}
              onClickSend={handleSendMessage}
            />
          </S.ChatInputContainer>
        )}
      </S.ChatContainer>
    </FsDrawer>
  );
};
