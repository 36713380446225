import { FsChat } from '@printi/ds-offset-react-core';
import styled, { keyframes } from 'styled-components';

export const ChatContainer = styled.div`
  position: relative;
  height: calc(100% - 120px);
  box-sizing: border-box;
`;

export const ChatHistory = styled.div`
  height: 100%;
  overflow-y: auto;

  & > div:first-child {
    padding-top: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  & > div {
    overflow-y: auto;
  }
  & > div::-webkit-scrollbar {
    display: none;
  }
`;

const MessageChatAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Chat = styled(FsChat)`
  margin-bottom: var(--spacing-4x);
  animation: ${MessageChatAnimation} 0.3s ease;

  > div {
    overflow-x: auto;
  }
`;

export const ChatInputContainer = styled.div``;

export const MessageChat = styled.div`
  padding-top: var(--spacing-1_5x);
`;
