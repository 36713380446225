import { api } from '@mf/common/config/axios/serviceApi';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { TAxiosDefaultConfig, TObject } from '../../defaults/types';
import {
  TBestSellingProductsResponse,
  TGetBestSellingProducts,
  TGetProduct,
  TGetProducts,
  TProduct,
  TProductsListing,
} from './types';

interface GetProductsListingParams {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sort?: TObject[] | TObject | string;
  populate?: string | TObject | string[];
  filters?: string | TObject;
}

interface GetBestSellingProductsParams {
  slug?: string;
  pageSize?: number;
}

const strapiProduct = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiCms: AxiosInstance = api({ baseUrl, token });

  const getProductsListing = async (
    params: GetProductsListingParams,
  ): Promise<TProductsListing> => {
    const { data }: TGetProducts = await apiCms.get('/v1/products', {
      params,
    });

    return data;
  };

  const getBestSellingProducts = async ({
    slug,
    pageSize = 8,
  }: GetBestSellingProductsParams): Promise<TBestSellingProductsResponse> => {
    const params = Object.assign(
      {
        pagination: {
          page: 0,
          pageSize: pageSize,
        },
        populate: ['banner_image', 'small_image', 'small_image_hover'],
        sort: [
          {
            ranking: 'asc',
          },
          {
            name: 'asc',
          },
        ],
      },
      slug && {
        filters: {
          classification_options: {
            slug: {
              $contains: slug,
            },
          },
        },
      },
    );

    const { data }: TGetBestSellingProducts = await apiCms.get('/v1/products', {
      params,
    });

    return data;
  };

  const getProduct = async ({ id }: { id: number }): Promise<TProduct> => {
    const { data }: TGetProduct = await apiCms.get(`/${id}`);

    return data.data;
  };

  interface ProductsQuery {
    params?: GetProductsListingParams;
  }

  const useProductsListing = (query: ProductsQuery) =>
    useInfiniteQuery<TProductsListing>({
      queryKey: ['products', query],

      queryFn: ({ pageParam = 0 }) =>
        getProductsListing({
          pagination: {
            page: pageParam,
            pageSize: query.params?.pagination?.pageSize,
          },
          sort: query.params?.sort,
          filters: query.params?.filters,
          populate: ['banner_image', 'small_image', 'small_image_hover'],
        }),

      getNextPageParam: (lastPage) =>
        lastPage.meta.pagination.page === lastPage.meta.pagination.pageCount
          ? undefined
          : lastPage.meta.pagination.page + 1,
    });

  const useBestSellingProducts = ({
    slug,
    pageSize,
  }: GetBestSellingProductsParams) => {
    return useQuery(['best-selling-products', { slug, pageSize }], () =>
      getBestSellingProducts({ slug, pageSize }),
    );
  };

  return {
    getProductsListing,
    getProduct,
    getBestSellingProducts,
    useProductsListing,
    useBestSellingProducts,
  };
};

export default strapiProduct;
