import Link from 'next/link';
import { useLayout } from '@mf/common/components/Media/Layout';
import {
  // ButtonSecondarySize,
  // FsButtonSecondary,
  // FsParagraph,
  // ButtonSecondarySize,
  // FsButtonSecondary,
  // FsParagraph,
  HeadingSize,
  HeadingTag,
  IconShapeSize,
} from '@printi/ds-offset-react-core';
import Image from 'next/image';
import * as S from './SearchResults.styles';

export type SearchResultType = {
  hits: Array<{
    document: {
      slug: string;
      id: string;
      name: string;
      price: string;
      small_image: string;
      minimum_quantity: string;
      final_product_id: string;
    };
  }>;
  found: number;
  page: number;
};

interface SearchResultsProps {
  results: SearchResultType;
  setIsOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  onProductClick?: (slug: string, final_product_id: string) => void;
}

export const SearchResults = ({
  results,
  setIsOpenDrawer,
  onProductClick,
}: SearchResultsProps) => {
  const { setShowSearchPage } = useLayout();

  const handleResultClick = (slug: string, final_product_id: string) => {
    onProductClick?.(slug, final_product_id);
    setIsOpenDrawer(false);
    setShowSearchPage(false);
  };

  return (
    <S.ResultsContainer>
      {results.found > 0 &&
        results.hits.map(({ document }) => (
          <Link
            href={`/${document.slug}`}
            key={document.id}
            onClick={() =>
              handleResultClick(document.slug, document.final_product_id)
            }
          >
            <S.CardList
              heading={document.name}
              headingProps={{ tag: HeadingTag.H2 }}
              description="A partir de"
              currencyProps={{
                price: `R$ ${document.price} /`,
                description: `${document.minimum_quantity || '1'} unid.`,
              }}
              image={
                <Image
                  src={document.small_image}
                  alt={document.name}
                  width={160}
                  height={160}
                />
              }
            />
          </Link>
        ))}
      {results.found === 0 && results.page > 0 && (
        <S.EmptyState>
          <S.EmptyStateTextContent>
            <S.IconShape icon="SearchLine" size={IconShapeSize.SM} />
            <S.Heading tag={HeadingTag.H3} size={HeadingSize.LG}>
              {`Produto não encontrado :(`}
            </S.Heading>
            {/* <FsParagraph>
              {`Nossa inteligência artificial pode ajudar a encontrar alternativas. Que tal?`}
            </FsParagraph> */}
          </S.EmptyStateTextContent>
          {/* <FsButtonSecondary
            size={ButtonSecondarySize.LG}
            icon="MagicLine"
            onClick={() => setIsOpenDrawer(true)}
          >
            Usar AI
          </FsButtonSecondary> */}
        </S.EmptyState>
      )}
    </S.ResultsContainer>
  );
};
