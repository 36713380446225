import {
  FsCardList,
  FsHeading,
  FsIconShape,
} from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const ResultsContainer = styled.div``;

export const CardList = styled(FsCardList)`
  margin: 0 auto;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-3x);
  padding: var(--spacing-7x) 0;
  gap: var(--spacing-5x);
`;

export const EmptyStateTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const IconShape = styled(FsIconShape)`
  margin-bottom: var(--spacing-4x);
`;

export const Heading = styled(FsHeading)`
  margin-bottom: var(--spacing-2x);
`;
