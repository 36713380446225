import Markdown from 'react-markdown';
import styled from 'styled-components';

export const StyledMarkdown = styled(Markdown)`
  display: flex;
  flex-direction: column;

  * {
    color: var(--text-paragraph-color);
    font-family: var(--font-family-base);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xs);
    letter-spacing: var(--letter-spacing-default);
    line-height: var(--line-height-lg);
    margin: 0;
  }

  strong {
    font-weight: var(--font-weight-bold);
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--size-2x);
    margin: var(--size-2x) 0;
  }

  a {
    font-weight: var(--font-weight-medium);
    text-decoration: underline;
  }
`;
