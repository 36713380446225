import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100% - var(--spacing-10x));
  height: var(--size-15x);
  padding: 0 var(--spacing-2x) 0 0;
  margin: 0 var(--spacing-5x);
  align-items: center;
  gap: var(--spacing-2x);
  border-bottom: var(--default-border-width) solid var(--elements-border-color);

  flex-shrink: 0;
  box-sizing: border-box;

  &:focus-within {
    border-bottom: var(--border-width-md) solid var(--active-border-color);
  }

  @media screen and (max-width: 767px) {
    height: var(--size-10x);
    width: 100%;
    margin: 0;
  }
`;

export const Input = styled.input`
  background: none;
  border: none;
  width: 100%;
  flex: 1 0 0;

  font-size: 40px;
  font-family: var(--font-family-base);
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  color: var(--active-placeholder-color);

  outline: none;

  &:placeholder {
    color: var(--text-placeholder-color);
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;
