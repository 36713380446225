import media from '@mf/common/utils/styles/media';
import styled from 'styled-components';
import { Container as CardProductSkeletonContainer } from '@mf/common/components/CardProductSkeleton/CardProductSkeleton.styles';
import { FsButtonSecondary } from '@printi/ds-offset-react-core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-5x);
  gap: var(--spacing-5x);
  width: 100%;
`;

export const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: dense;
  gap: var(--spacing-6x) var(--spacing-3x);
  width: 100%;

  ${media.lessThan('desktop')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.lessThan('mobile')} {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    gap: var(--spacing-2x);
    width: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CardProductWrapper = styled.div`
  display: flex;
  min-width: 240px;
  width: 240px;
  cursor: pointer;
  ${media.greaterThan('touch')} {
    width: 322px;
  }

  ${CardProductSkeletonContainer} {
    width: 100%;
  }
`;

export const ButtonSecondary = styled(FsButtonSecondary)`
  width: 197px;
  margin: 0 auto;
`;
